@import '../../styles/theme.scss';

:global(div[data-component="Mobilemenu"]) {
  height: 100%;
}

.root {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}
.scrollable {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: $spacing-unit * 3.5;
}

.header {
  padding: $spacing-unit * 3.5;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: $spacing-unit * 2.5;
}

.logo {
  width: 100%;
  display: block;
  height: var(--mobileLogoHeight);
}

.withMask {
  mask: var(--maskMobileLogo);
}

.withImg {
  background: var(--maskMobileLogo);
}

.icon {
  position: absolute;
  font-size: 22px;
  right: 22px;
  cursor: pointer;
}

.padding {
  padding: 0 $spacing-unit * 3.5;
}

.search {
  position: relative;
}

.input {
  height: 55px;
  padding-top: $spacing-unit * 4;
  padding-bottom: $spacing-unit * 4;
  color: $black-bis;
  letter-spacing: 1px;
  margin-bottom: $spacing-unit * 4;
  border: 1px solid $black-bis;
  width: 100%;

  &::placeholder {
    font-size: 18px;
    text-transform: uppercase;
    line-height: 18px;
    color: $black-bis;
  }
}
.btn {
  position: absolute;
  right: 20px;
  top: 17px;
  font-size: 16px;
}

.itemList {
  margin: 0;
  display: grid;
  row-gap: $spacing-unit * 3;
  grid-auto-flow: row;
  list-style: none;
}

.itemLink {
  position: relative;
  display: block;
  > img {
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
  }
  > button,
  > a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    padding: 0 $spacing-unit * 2;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;
    width: 100%;
    font-family: $family-secondary;
    font-weight: 600;
    text-align: left;
    height: 100%;
  }
}

.submenuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $white;
  z-index: 2;
  transform: translate3d(100%, 0, 0);
  transition: transform 300ms ease;
  display: none;
}

.submenuHeading {
  height: 55px;
  display: flex;
  align-items: center;
}

.title {
  margin-left: $spacing-unit * 2;
  font-weight: 600;
}

.submenuList {
  margin-left: 0;
  list-style: none;
}

.submenuItem {
  height: 68px;
  display: flex;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
    flex: 1;

    img {
      margin-right: $spacing-unit * 2;
      width: 50px;
      height: 50px;
    }

    span {
      flex: 1;
      font-size: 16px;
      font-weight: var(--familyPrimaryBold);
      color: $black-bis;
    }

    svg {
      margin-right: $spacing-unit;
    }
  }
}

.ul {
  margin: 0;
  list-style: none;
}

.footer {
  margin-top: $spacing-unit * 5;
}

.footerItem {
  padding: $spacing-unit * 3.5 0;
  border-top: 1px solid $black;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 0;

  summary {
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span:global(.icon):before {
        font-size: 30px;
        line-height: 20px;
      }
    }
  }

  details {
    width: 100%;
  }

  span:global(.icon):last-child:before {
    content: "+";
  }

  details[open] {
    span:global(.icon):last-child:before {
      content: "-";
    }
  }

  ul {
    list-style: none;
    margin-top: $spacing-unit * 3.5;
  }
}
