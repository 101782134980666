$black: #000;
$black-bis: #111;
$white: #fff;
$grey-dark: #6e6e6e;
$grey: #919191;
$grey-lighter-new: #999999;
$pink: #df3684;
$light-pink: #FDD1D2;
$danger: #B63456;
$success-dark: #2d8a4e;
$gold-dark: #C4AC6B;

$family-primary: var(--familyPrimary);
$family-secondary: var(--familySecondary);

$xm-mobile: 375px;
$sm-mobile: 414px;
$tablet: 768px;
$desktop: 1088px;
$desktop-page: 1024px;
$winsceen: 1280px;
$small-mobile: 359px !default;

$spacing-unit: 6px;

@font-face {
  font-family: 'jv-icons';
  src:
      url('//cdn.shopify.com/s/files/1/0275/9078/7160/t/19/assets/jv-icons.ttf?20211209') format('truetype'),
      url('//cdn.shopify.com/s/files/1/0275/9078/7160/t/19/assets/jv-icons.woff?20211209') format('woff'),
      url('//cdn.shopify.com/s/files/1/0275/9078/7160/t/19/assets/jv-icons.svg?20211209') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$jv-icons-font: "jv-icons"